import React from "react"
import './pageHero.css'

function PageHero(props){
  return (
    <div
      className="inner-page-hero flex"
      style={{backgroundImage: `url(${props.image})`}}>
      <h1>{props.title}</h1>
    </div>
  )
}

export default PageHero
